import moment from "moment";
import { RichText } from "prismic-reactjs";
import React, { ReactElement } from "react";

import { Employee } from "../../../_types/employee";
import { RichTextType } from "../../../_types/prismic-react";
import CategoryIcon from "../../categoryIcon";
import PrismicHref from "../../prismicHref";
import classes from "./post.module.css";

export interface PostProp {
	author: Employee;
	date: string;
	title: RichTextType;
	post_category: "Newsletter" | "Blog Post" | "Update";
	short_description: RichTextType;
	_meta: {
		uid: string;
	};
}

const Post = (props: PostProp): ReactElement => {
	return (
		<article className={classes.postContainer}>
			<PrismicHref link={props} className={classes.postLink}>
				<div className={classes.postInfo}>
					<CategoryIcon postCategory={props.post_category} />
					<span className={classes.label}>{props.post_category}</span>
				</div>
				<div className={classes.postedAt}>
					{moment(props.date).format("MMMM Do, YYYY")}
				</div>
				<div className={classes.articleContent}>
					<div className={classes.title}>
						{RichText.asText(props.title)}
					</div>
					<div className={classes.teaser}>
						{RichText.asText(props.short_description)}
					</div>
				</div>
				<div className={classes.footer}>
					<div className={classes.author}>
						{props.author?.employee_photo?.url && <img
							src={props.author.employee_photo.url}
							alt={props.author.employee_photo.alt}
							className={classes.authorImage}
						/>}
						<div className={classes.authorName}>
							{props.author?.employee_name && <div>
								By{" "}
								<strong>
									{RichText.asText(
										props.author.employee_name
									)}
								</strong>
							</div>
							}
							{props.author?.employee_title &&
								<div>
									{props.author.employee_title &&
										RichText.asText(
											props.author.employee_title
										)}
								</div>
							}
						</div>
					</div>
				</div>
			</PrismicHref>
		</article>
	);
};

export default Post;
