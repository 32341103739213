import clsx from "clsx";
import React, { ReactElement } from "react";

import NewsletterContainer from "../newsletter";
import Post, { PostProp } from "./post/post";
import postClasses from "./post/post.module.css";
import classes from "./posts.module.css";

interface Props {
	posts: [{ node: PostProp }];
}

const Posts = (props: Props): ReactElement => {
	return (
		<section className={classes.container}>
			{props.posts.map(({ node }) => {
				return <Post {...node} key={node._meta.uid} />;
			})}
			<article className={postClasses.postContainer}>
				<div
					className={clsx(
						postClasses.postLink,
						postClasses.newsletterContainer
					)}
				>
					<NewsletterContainer />
				</div>
			</article>
		</section>
	);
};

export default Posts;
