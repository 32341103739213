import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import React from "react";

import Layout from "../components/layout";
import Masthead from "../components/masthead";
import Posts from "../components/posts";
import SEO from "../components/seo";
import HorizontalLine from "../components/slices/horizontalLine";
import classes from "./articles.module.css";

export const query = graphql`
	{
		prismic {
			allArticle_homes(uid: null) {
				edges {
					node {
						title
						description
						_meta {
							uid
							id
						}
					}
				}
			}
			allPosts(sortBy: date_DESC) {
				edges {
					node {
						_meta {
							uid
							type
						}
						_linkType
						author {
							... on PRISMIC_Employee {
								employee_name
								employee_photo
								employee_title
							}
						}
						date
						title
						post_category
						short_description
						_meta {
							uid
							id
						}
					}
				}
			}
		}
	}
`;

const ArticlesPage = props => {
	const articleHome = props.data.prismic.allArticle_homes.edges
		.slice(0, 1)
		.pop().node;
	const posts = props.data.prismic.allPosts.edges;
	return (
		<Layout>
			<SEO title="Articles" />
			<div className={classes.container}>
				<h1 className={classes.header}>
					{RichText.asText(articleHome.title)}
				</h1>
				<h4 className={classes.description}>
					{RichText.asText(articleHome.description)}
				</h4>
				{posts && <Posts posts={posts} />}
			</div>
		</Layout>
	);
};

export default ArticlesPage;
